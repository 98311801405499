import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';
import _ from 'lodash';

import CatList1 from './CatList/CatList1';
import Banner from './CatList/Banner';
import { getOneOf } from '../lib/utils';

const StyledContainer = styled(Container)`
  min-height: 40vh;
  margin-bottom: 40px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;

export const LocationPageTemplate = ({ post, isPreview, pageContext }) => {
  return (
    <div>
      <Helmet>
        <title>BestFit</title>
        <link rel="canonical" href="https://best-fit.app/" />
      </Helmet>
      <section>
        <StyledContainer>
          <Banner
            item={_.get(post, 'frontmatter')}
            paths={[
              {
                label: 'Home',
                href: '/',
              },
              {
                label: _.get(pageContext, 'location'),
              },
            ]} 
          />
          <CatList1 cats={getOneOf(post, 'frontmatter.categories').map(cat => {
            return {
              ...cat,
              slug: `${pageContext.locPath}${_.trimStart(cat.slug, '/')}`
            };
          })} />
        </StyledContainer>
      </section>
    </div>
  )
}


export default LocationPageTemplate;
