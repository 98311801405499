import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import _ from 'lodash'

import HomeLayout from '../components/HomeLayout';

import { HTMLContent } from '../components/Content'

import LocationPageTemplate from '../components/LocationPageTemplate'
import { useMetaOverride } from '../lib/hoc/useMeta';
import '../graphql/newsFragment';

const LocationPage = ({ data, pageContext }) => {
  const { markdownRemark: defaultPost } = data;
  const post = useMetaOverride('meta', defaultPost);

  const locMeta = _.find(_.castArray(_.get(post, 'frontmatter.areas')), { slug: pageContext.locPath });
  return (
    <HomeLayout headerText={_.get(post, 'frontmatter.description')}>
      <LocationPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        post={{
          frontmatter: {
            ...post.frontmatter,
            ...locMeta,
          },
        }}
        pageContext={pageContext}
      />
    </HomeLayout>
  )
}

LocationPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const homePageQuery = graphql`
  query LocationPage($locRegex: String) {
    markdownRemark(fields: { slug: { eq: "/meta/"}}) {
      html
      frontmatter {
        title
        description
        areas {
          title
          description
          slug
          actionText
          actionLink
        }
        categories {
          name
          title
          description
          className
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          slug
          airtable
        }
      }
    }
    allAirtable(filter: { data: { Area: { regex: $locRegex }}}) {
      edges {
        node {
          ...resourceFragment
        }
      }
    }    
  }
`

export default LocationPage;
